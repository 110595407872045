<template>
  <div>
    <portal to="header">
      <terminal-title :text="$t('Return Parcel')" />
    </portal>

    <portal to="settings">
      <tiles :schema="tilesSchema" />
    </portal>

    <div class="q-pa-md">
      <form-builder :schema="schema" />
    </div>
  </div>
</template>

<script>
// Vuex
export default {
  name: 'ReturnParcel',
  data () {
    return {
      model: {
        extId: '',
        _embedded: {
          warehouse: null
        }
      }
    }
  },
  computed: {
    tilesSchema () {
      return [
        {
          id: 1,
          label: this.$t('Save'),
          icon: 'save',
          value: true,
          onChanged: () => {
            this.save()
          }
        }
      ]
    },
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                value: this.model.extId,
                inputType: 'text',
                label: this.$t('Document number'),
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                onChange: value => {
                  this.model.extId = value
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Warehouse'),
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                value: this.model._embedded.warehouse,
                required: true,
                customLabel (row) {
                  if (!row) {
                    return ''
                  }

                  return `${row.name} (${row.id})`
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search
                  }

                  return this.$service.warehouse.getAll(query)
                },
                onChange: value => {
                  this.model._embedded.warehouse = value
                }
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    handleBack () {
      this.$router.push('/terminal/return-parcels')
    },
    save () {
      const data = this.$utils.convertEmbedded(this.model)

      return this.$service.returnParcel.save(data)
        .then(() => {
          this.handleBack()
        })
    }
  }
}
</script>
